import axios from './index'

export default {
  submitNewProductType(model) {
    return axios.post('/productType/upsert', model)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getProductBrand() {
    return axios.get('/data/entity/productBrand')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getProductType() {
    return axios.get('/data/entity/productType')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getProductStockType() {
    return axios.get('/data/entity/productStockType')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  submitNewProduct(model) {
    return axios.post('/product/upsert', model)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getProductTypeNameExists(id, name) {
    return axios.get('/productType/productTypeIsExists?productTypeName=' + name + '&productTypeId=' + id)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getProductTypeForEdit(id) {
    return axios.get('/productType/getProductTypeForEdit?id=' + id)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getProduct(id) {
    return axios.get('/product/getProductForEdit?id=' + id)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getProductStockState(id) {
    return axios.get('/Product/ProductStockState?productId=' + id)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getProductStockInformation(id) {
    return axios.get('/Product/ProductStockInformation?productId=' + id)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateProductStock(productID, newStock, description) {
    return axios.post("/Product/UpdateStock", {
      productID: productID,
      newStock: newStock,
      description: description
    }).then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
}
