<template>
  <b-container fluid>
    <ValidationObserver ref="productObserver" v-slot="{ invalid }">
      <form>
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-form-group class="col-md-6" :label="$t('Name')" label-for="name">
                <ValidationProvider :name="$t('Name')" rules="required" v-slot="{ errors }">
                  <b-form-input :class="(errors.length > 0 ? ' is-invalid' : '')" v-model="model.name " type="text"></b-form-input>
                  <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>

              <b-form-group class="col-md-6" :label="$t('ProductType')" label-for="productType">
                <ValidationProvider :name="$t('ProductType')" rules="required" v-slot="{ errors }">
                  <v-select transition="" v-model="model.productTypeID"
                            :reduce="s => s.id" label="name"
                            :options="productTypeList"
                            :class="(errors.length > 0 ? ' is-invalid' : '')"
                            required>
                  <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
                  <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>

              <b-form-group class="col-md-6" :label="$t('ProductCode')" label-for="barcodeCode">
                <b-form-input v-model="model.barcodeCode " type="text"></b-form-input>
              </b-form-group>

              <b-form-group class="col-md-12" :label="$t('Note')" label-for="productNote">
                <b-form-textarea id="productNote" v-model="model.notes"></b-form-textarea>
              </b-form-group>
            </b-row>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-form-group class="col-md-12" :label="$t('StockType')" label-for="stockType">
                <ValidationProvider :name="$t('StockType')" rules="required" v-slot="{ errors }">
                  <v-select transition="" v-model="model.productStockTypeID"
                            :reduce="s => s.id" label="name"
                            :options="stockTypeList"
                            :class="(errors.length > 0 ? ' is-invalid' : '')"
                            required>
                  <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
                  <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>

              <template v-if="!isEdit">
                <b-form-group class="col-md-6" :label="$t('FirstStock')" label-for="stock">
                  <ValidationProvider :name="$t('FirstStock')" rules="required" v-slot="{ errors }">
                    <b-form-input :class="(errors.length > 0 ? ' is-invalid' : '')" v-model="model.stock " type="text" @keypress="$root.isNumber"></b-form-input>
                    <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group class="col-md-6" :label="$t('ExpireDateOfFirstStock')" label-for="lastUsageDate">
                  <date-picker v-model="model.lastUsageDate"
                               :tag-name="'ExpireDateOfFirstStock'"
                               :is-required="false" />
                </b-form-group>
              </template>

              <b-form-group class="col-md-6" :label="$t('StockAlertCount')" label-for="stockAlertCount">
                <b-form-input v-model="model.stockAlertCount " type="text" @keypress="$root.isNumber"></b-form-input>
              </b-form-group>

              <b-form-group class="col-md-12" :label="$t('StockAlertEmail')" label-for="stockAlertMailAddress">
                <b-form-input v-model="model.stockAlertMailAddress " type="text"></b-form-input>
              </b-form-group>
            </b-row>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-form-group class="col-md-12" :label="$t('Rate')" label-for="currency">
                <ValidationProvider :name="$t('Rate')" rules="required" v-slot="{ errors }">
                  <v-select transition="" v-model="model.currencyID"
                            :reduce="s => s.currencyId" label="symbol"
                            :options="currencyList"
                            :class="(errors.length > 0 ? ' is-invalid' : '')"
                            required>
                  <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
                  <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>

              <b-form-group class="col-md-6" :label="$t('Price')" label-for="price">
                <ValidationProvider :name="$t('Price')" rules="required" v-slot="{ errors }">
                  <b-form-input :class="(errors.length > 0 ? ' is-invalid' : '')" v-model="model.price" @keypress="$root.isNumber"></b-form-input>
                  <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>

              <b-form-group class="col-md-6" :label="$t('PriceWithCard')" label-for="priceWithCard">
                <ValidationProvider :name="$t('PriceWithCard')" rules="required" v-slot="{ errors }">
                  <b-form-input :class="(errors.length > 0 ? ' is-invalid' : '')" v-model="model.priceWithCard" @keypress="$root.isNumber"></b-form-input>
                  <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>

              <!--<b-form-group class="col-md-6" :label="$t('InvoiceName')" label-for="invoiceName">
                <b-form-input v-model="model.invoiceName"></b-form-input>
              </b-form-group>-->

              <b-form-group class="col-md-6" :label="$t('TaxRate')" label-for="vatPercentage">
                <b-form-input v-model="model.vatPercentage" :placeholder="$t('Example18')" @keypress="$root.isNumber"></b-form-input>
              </b-form-group>
            </b-row>
          </template>
        </iq-card>

        <modal-footer-button :set-is-submitting="isSubmitting"
                             @submit="submitNewProduct"></modal-footer-button>
      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
  import commonServices from '../../../services/common'
  import productServices from '../../../services/product'

  import DatePicker from '../../../components/shared/DatePicker';
  import ModalFooterButton from '../../../components/shared/ModalFooterButton'
  import { refreshSmartDuoData } from '../../../helpers/dataRefresher'
  export default {
    name: 'Product',
    components: {
      DatePicker,
      ModalFooterButton
    },
    props: {
      productId: String
    },
    data() {
      return {
        model: {
          id: '',
          name: '',
          vatPercentage: '',
          productBrandID: '',
          productTypeID: '',
          barcodeCode: null,
          productStockTypeID: 1,
          stock: 0,
          lastUsageDate: '',
          stockAlertCount: null,
          stockAlertMailAddress: null,
          notes: null,
          currencyID: this.mainCurrencyId,
          invoiceName: null,
          price: '',
          priceWithCard: ''
        },
        productTypeList: [],
        stockTypeList: [],
        currencyList: [],
        isEdit: false,
        isSubmitting: false
      }
    },
    methods: {
      async submitNewProduct() {
        const isValid = await this.$refs.productObserver.validate();
        if (!isValid) {
          this.$toastr.error(this.$t("ValidationMessage.EmptyAndValidateError"));
          return;
        }

        this.isSubmitting = true;

        productServices.submitNewProduct(this.model).then((response) => {
          this.isSubmitting = false;
          if (response) {
            this.$emit('closeModal');
            this.$toastr.success(this.$t("Success"));
            refreshSmartDuoData();
          }
          else {
            this.$toastr.error(this.$t("Error"));
          }
        }).finally(() => { this.isSubmitting = false; });
      },
      getCurrencyList() {
        commonServices.getCurrencyList().then((response) => {
          if (response) {
            this.currencyList = response;
          }
        })
      },
      getProductType() {
        productServices.getProductType().then((response) => {
          if (response) {
            this.productTypeList = response;
          }
        })
      },
      getProductStockType() {
        productServices.getProductStockType().then((response) => {
          if (response) {
            this.stockTypeList = response;
          }
        })
      },
      getProduct() {
        productServices.getProduct(this.model.id).then((response) => {
          if (response) {
            this.model = response;
          }
        })
      },
    },
    watch: {
      'model.price': function (val) {
        if (val)
          this.model.price = val.toString().replace(',', '.');
      },
      'model.priceWithCard': function (val) {
        if (val)
          this.model.priceWithCard = val.toString().replace(',', '.');
      },
      'model.vatPercentage': function (val) {
        if (val)
          this.model.vatPercentage = val.toString().replace(',', '.');
      },
      'model.stock': function (val) {
        if (val)
          this.model.stock = val.toString().replace(',', '.');
      },
      'model.stockAlertCount': function (val) {
        if (val)
          this.model.stockAlertCount = val.toString().replace(',', '.');
      }
    },
    computed: {
      mainCurrencyId: function () {
        return this.$store.getters['Auth/tenantInfo'].mainCurrencyId;
      }
    },
    mounted() {
      if (this.productId && this.productId.length > 0) {
        this.model.id = this.productId;
        this.getProduct();
        this.isEdit = true;
      }
      this.model.currencyID = this.mainCurrencyId;
      this.getProductType();
      this.getProductStockType();
      this.getCurrencyList();
    }
  }
</script>
